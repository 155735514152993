import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

// Components
import ContentWrapper from "./ContentWrapper"
import Button from "./atoms/Button"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Constants
import { getUrl } from "../constants/paths"

// Styles
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import textStyles, { fontWeights } from "../styles/textStyles"

// Types
import { IColumns, JustifyColumn } from "../prismic-types/homepage"

interface IProps extends IColumns {
  id?: string
  className?: string
  color?: string
  notStack?: boolean
  justifyColumn?: JustifyColumn
  backgroundColor: string
}

const Columns: React.FC<IProps> = ({
  id = "home-columns",
  columns,
  color,
  className,
  notStack,
  justifyColumn = "center",
  backgroundColor,
}) => {
  const { getLocalizedLink } = useLocale()
  return (
    <Container className={className} background={backgroundColor}>
      <StyledContentWrapper>
        {columns.map((column, index) => (
          <Column
            key={`column-${index}`}
            margin={index === 1 && !notStack}
            aligned={
              index === 0 || notStack ? "start" : index === 1 ? "center" : "end"
            }
            justifyColumn={justifyColumn}
          >
            <ColumnContent color={color}>
              {column.title && <Title color={color}>{column.title}</Title>}
              {column.image && column.image.url && (
                <Image>
                  <GatsbyImage
                    fluid={column.image.url}
                    alt={column.image.alt}
                  />
                </Image>
              )}
              {column.subTitle && (
                <Subtitle id={id} justifyColumn={justifyColumn}>
                  {column.subTitle}
                </Subtitle>
              )}
              {column.text && (
                <Text
                  justifyColumn={justifyColumn}
                  id={id}
                  dangerouslySetInnerHTML={{
                    __html: column.text,
                  }}
                />
              )}
              {column.button && column.button.label && (
                <StyledButton
                  variant="primary"
                  label={column.button.label}
                  href={getLocalizedLink(getUrl(column.button?.link))}
                />
              )}
            </ColumnContent>
          </Column>
        ))}
      </StyledContentWrapper>
    </Container>
  )
}

export default Columns

const fontSizeHelper = (id: string) => {
  const FontSize: Record<string, string> = {
    "home-columns": `font-size: 32px !important; line-height: 40px !important;`,
    "manifesto-columns":
      "font-size: 22px !important; line-height: 30px !important;",
    "career-framework-columns":
      "font-size: 22px !important; line-height: 30px !important;",
  }

  return FontSize[id] || null
}

const textColorHelper = (id: string) => {
  const FontSize: Record<string, string> = {
    "home-columns": `color: ${colors.main["300"]};`,
    "manifesto-columns": `color: ${colors.main["100"]};`,
    "career-framework-columns": `color: ${colors.main["100"]};`,
  }
  return FontSize[id] || null
}

const subTitleColorHelper = (id: string) => {
  const FontSize: Record<string, string> = {
    "home-columns": `color: ${colors.actionUI};`,
    "manifesto-columns": `color: ${colors.main["100"]};`,
    "career-framework-columns": `color: ${colors.main["100"]};`,
  }

  return FontSize[id] || null
}

const Container = styled.section<{ background: string }>`
  padding: 80px 0;
  background: ${({ background }) => background};

  ${mq.from.M`
    padding: 120px 0;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: grid;
  grid-template-columns: 1fr; // By default (for very small screens), it's 1 column
  grid-column-gap: 30px;
  gap: 30px;
  align-items: top;

  ${mq.from.M`
  grid-template-columns: repeat(3, 1fr); // Three columns for medium and above
`};
`

const Column = styled.div<{
  aligned: string
  margin?: boolean
  justifyColumn?: JustifyColumn
}>`
  display: grid;
  justify-items: ${({ justifyColumn }) => justifyColumn};
  align-content: space-between;

  ${mq.from.M`
     margin-top: ${({ aligned }: { aligned: string }) =>
       aligned === "start"
         ? "0px"
         : aligned === "center"
         ? "80px"
         : aligned === "end"
         ? "160px"
         : "0px"};
  `}
`

const ColumnContent = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;

  ${({ color }) =>
    color &&
    `
      border-left: 1px solid ${color};
      padding-left: 24px;;
    `};
`

const Title = styled.h3<{ color?: string }>`
  ${textStyles.titleL};
  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}
  margin-bottom: 24px;
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 18px;

  img {
    margin-bottom: 0;
    min-width: 100%;
  }
`

const Subtitle = styled.h5<{ id: string; justifyColumn: string }>`
  ${textStyles.titleM};
  ${({ id }) => fontSizeHelper(id)};
  ${({ id }) => subTitleColorHelper(id)};
  text-align: ${({ justifyColumn }) => justifyColumn};
`

const Text = styled.div<{ id: string; justifyColumn: JustifyColumn }>`
  text-align: ${({ justifyColumn }) => justifyColumn};
  margin-top: 16px;
  ${({ id }) => textColorHelper(id)};
  > p {
    ${textStyles.body};
    margin-bottom: 16px;
  }

  > b {
    font-weight: ${fontWeights.bold};
  }

  > ul {
    margin-bottom: 0;

    > li {
      margin-bottom: 2px;
    }
  }

  > p:last-child,
  li:last-child {
    margin-bottom: 0;
  }
`

const StyledButton = styled(Button)`
  margin-top: 24px;
`
